import { useAuthStore } from "~~/stores/auth";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const auth = useAuthStore();
  const token = auth.token;
  const isAdminRoute = to.meta.key === 'admin';
  const urlRedirect = isAdminRoute ? '/admin/login' : '/user/login';
  if ((token !== undefined) && (token !== null) && (token !== '')) {
    const statusCodeRequest = isAdminRoute ? await auth.checkTokenAdminUser() : await auth.checkToken();

    if (statusCodeRequest === 401) {
      const refreshedToken = await auth.refreshTokenAuth();

      if (!refreshedToken) {
        auth.setRedirectUrl(to.fullPath);
        return navigateTo(urlRedirect);
      }
    }

    if (statusCodeRequest !== 200) {
      auth.setRedirectUrl(to.fullPath);
      return navigateTo(urlRedirect);
    }
  } else {
    auth.setRedirectUrl(to.fullPath);
    return navigateTo(urlRedirect);
  }
});
